import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

export default function Soundtracks() {
  return (
    <Layout>
      <Helmet>
        <title>Game Soundtracks | Nathan Pasko</title>
        <meta name="description" content="In addition to writing distorted electronic music under the name kryzkniv, Nathan Pasko also composes music for games. Listen to selected game soundtracks with renditions ranging from traditional to wacky." />
      </Helmet>
      <div className="project-page">
        <div className="columns">
          <div className="col">
            <h1>Game Soundtracks</h1>
            <h2>Giant's Chalice</h2>
            <p>
              Music composed and arranged by Nathan Pasko, except: the Title
              Theme for <a href="/giantschalice/">Giant's Chalice</a> is a
              section of Claude Debussy's <i>Dialogue du vent et de la mer</i>,
              arranged by Nathan Pasko.
            </p>
            <p>
              I used MIDI instruments to add to the game's old tech motif. I
              didn't want the music to be a static backdrop. The mix of
              instruments changes with the player's position as they explore.
              Sound emitters are attached to each weapon in the game, so as the
              player collects different equipment, the soundtrack changes further.
            </p>
          </div>
          <div className="col">
            <iframe
              style={{ border: 0, width: "98%", height: "307px" }}
              src="https://bandcamp.com/EmbeddedPlayer/album=3383965057/size=large/bgcol=ffffff/linkcol=2ebd35/artwork=small/transparent=true/"
              seamless
            >
              <a href="http://kryzkniv.bandcamp.com/album/giants-chalice-soundtrack">
                Giant&#39;s Chalice soundtrack
              </a>
            </iframe>
          </div>
        </div>
        <div className="columns">
          <div className="col">
            <h2>S. Pet</h2>
            <p>
              Music composed and arranged by Nathan Pasko. The mechanics of{" "}
              <a href="/spet/">S. Pet</a> were inspired largely by the virtual
              pet toys of the 90s (Digimon, Tamagotchi) but the screen displayed
              only 4 colors, imitating the GameBoy Color. To make the S. Pet
              soundtrack I programmed MIDI instruments that were similar to the
              sound capabilities of the GameBoy Color (whose sound chip in turn
              was inherited from the GameBoy, and from the NES). The player can
              toggle the background music on and off, and choose between these
              looping tunes.
            </p>
          </div>
          <div className="col">
            <iframe
              style={{ border: 0, width: "98%", height: "307px" }}
              src="https://bandcamp.com/EmbeddedPlayer/album=913668923/size=large/bgcol=ffffff/linkcol=f171a2/artwork=small/transparent=true/"
              seamless
            >
              <a href="http://kryzkniv.bandcamp.com/album/s-pet-original-soundtrack">
                S-Pet soundtrack by kryzkniv
              </a>
            </iframe>
          </div>
        </div>
        <div className="columns">
          <div className="col">
            <h2>Insane Trap</h2>
            <p>
              Music composed and arranged by Nathan Pasko. The soundtrack for
              Insane Trap was inspired by afrobeat, jazz, and new wave.
              Development of the game never progressed beyond a few levels, but
              selections from the soundtrack are preserved here.
            </p>
          </div>
          <div className="col">
            <iframe
              style={{ border: 0, width: "98%", height: "307px" }}
              src="https://bandcamp.com/EmbeddedPlayer/album=3295607726/size=large/bgcol=ffffff/linkcol=7137dc/artwork=small/transparent=true/"
              seamless
            >
              <a href="http://kryzkniv.bandcamp.com/album/insane-trap-soundtrack">
                Insane Trap soundtrack by kryzkniv
              </a>
            </iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
}
